import React from 'react';

const AboutPage: React.FC = () => {
    return (
        <div>
            <h1>Hello world!</h1>
            <p>THis is the about page of our application.</p>
        </div>
    )
}

export default AboutPage;
