import React from 'react';
import classes from '../GenericCourses.module.css';

import OfferBanner from '../../../components/OfferBanner';

import medicalBannerImage from "../../../assets/medical-banner.jpg";
import medicalPhoto1 from "../../../assets/medical1.jpg";
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive'

const MedicalCourses = () => {
    const { t } = useTranslation('translation');
    const hygraph = t;

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1199px)' });

    return (
        <section id="description">
        <div>
            <OfferBanner bannerImage={medicalBannerImage}
                bannerAlt="imagine de prezentare a serviciilor pentru cadre medicale"
                bannerTitle={hygraph('Courses.Medical.Title')}
            ></OfferBanner>

            <div className={classes.masonryWithColumns}>
                <div dangerouslySetInnerHTML={{ __html: hygraph('Courses.Medical.Section1')} } className={!isTabletOrMobile ? classes.offerSection : classes.offerSectionMobile} />
                <div className={classes.offerSection}>
                    <div className={classes.imageFrame}>
                        <img src={medicalPhoto1} alt='stetoscop'>
                        </img>
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: hygraph('Courses.Medical.Section2')} } className={classes.offerSection} />
            </div>

            <div className={classes.registryButton}>
                <HashLink smooth={true} to='/register#form'>
                    <button className='primary-button big-button'>
                        {hygraph('Courses.Medical.ButtonLabel')}
                    </button>
                </HashLink>
            </div>
        </div>
        </section>
    )
}

export default MedicalCourses;
