import React from 'react';
import MainSection from './sections/MainSection';
import AboutUsSection from './sections/AboutUsSection';
import OfferSection from './sections/OfferSection';
import DemoSection from './sections/DemoSection';

const HomePage: React.FC = () => {
    return (
        <div>
            <section id="home">
                <MainSection/>
            </section>
            <section id="about-us">
                <AboutUsSection/>
            </section>
            <section id="demo">
                <DemoSection/>
            </section>
            <section id="offer">
                <OfferSection/>
            </section>
        </div>
    )
}

export default HomePage;
