import classes from './Header.module.css'
import logo from '../../assets/logo.png'
import underline from '../../assets/highlight_line.png'
import { useMediaQuery } from 'react-responsive'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Sidebar from './sidebar/Sidebar';
import { useContext} from 'react';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import SidebarContext from '../../utils/context/SidebarContext';

function Header() {
    const { t } = useTranslation();
    const hygraph = t;

    const sidebarCtx = useContext(SidebarContext)

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1220px)' })

    function openModal() {
        sidebarCtx.open();
    }

    function closeSidebar() {
        sidebarCtx.close();
    }
    
    return (
        <>
            {!isTabletOrMobile && <header className={classes.container}>
                <div className={classes.containerContent}>
                    <HashLink smooth={true} to="/#about-us" className={classes.item}>{hygraph('Config.AboutMeLabel')}
                        <img src={underline} alt="underline highlight doodle" className={classes.underline}></img>
                    </HashLink>
                    <HashLink smooth={true} to="/#demo" className={classes.item}>{hygraph('Config.LearnLabel')}
                        <img src={underline} alt="underline highlight doodle" className={classes.underline}></img>
                    </HashLink>
                    <HashLink smooth={true} to="/#offer" className={classes.item}>{hygraph('Config.MyOfferLabel')}
                        <img src={underline} alt="underline highlight doodle" className={classes.underline}></img>
                    </HashLink>
                    <HashLink smooth={true} to="/reviews#reviews" className={classes.item}>Recenzii
                        <img src={underline} alt="underline highlight doodle" className={classes.underlineSm}></img>
                    </HashLink>
                    <HashLink smooth={true} to="/register#form" className={classes.item}>
                        <div className={classes.signUp}>{hygraph('Config.RegisterLabel')}</div>
                    </HashLink>
                </div>
                <HashLink smooth={true} to="/#home" className={classes.containerLogo}>
                    <img src={logo} alt='logo' className={classes.logo}/>
                </HashLink>
            </header>}
            {isTabletOrMobile && <><header className={classes.containerMobile}>
                <div className={classes.containerLogoMobile}>
                    <HashLink smooth={true} to="/#home">
                        <img src={logo} alt='logo' className={classes.logoMobile}/>
                    </HashLink>
                </div>
                {!sidebarCtx.isSidebarOpen && 
                    <div className={classes.hamburgerMenuContainer} onClick={openModal}>
                        <MenuIcon fontSize='large' className={classes.hamburgerMenu}/>
                    </div>}
                {sidebarCtx.isSidebarOpen && 
                    <div className={classes.hamburgerMenuContainer} onClick={closeSidebar}>
                        <CloseIcon fontSize='large' className={classes.hamburgerMenu}/>
                    </div>}
            </header>
            <div className={classes.headerMobileSpacing}>
            </div></>}
            {sidebarCtx.isSidebarOpen && <Sidebar/>}
        </>
    )
}

export default Header;