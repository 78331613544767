export const CookieConsentService = {
    COOKIE_CONSENT_KEY: "_CK_USER_VALUE",

    getCookieConsent: () => {
        const consentValue = localStorage.getItem(CookieConsentService.COOKIE_CONSENT_KEY);
        return consentValue ? JSON.parse(consentValue) : null;
    },

    setCookieConsent: (isConsent) => {
        localStorage.setItem(CookieConsentService.COOKIE_CONSENT_KEY, JSON.stringify(isConsent));
    },
}