import React from 'react';

import OfferBanner from '../../components/OfferBanner';
import reviewsBannerImage from "../../assets/review-banner.jpg";
import classes from './LongTestimonials.module.css';

import { AppResourcesFetch } from '../../services/AppResourcesFetch';
import { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';

const LongTestimonials = () => {
    const [reviews, setReviews] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        async function loadLongReviews() {
            setLoading(true);
            const fetchedData = await AppResourcesFetch.getLongReviews();
            setReviews(fetchedData.recenziiLungis[0]);
            setLoading(false);
        }
        loadLongReviews();
    }, []);

    return (
        <>
            <section id="reviews">
                <OfferBanner bannerImage={reviewsBannerImage}
                    bannerAlt="imagine de prezentare a paginii de testimoniale"
                    bannerTitle="Recenziile elevilor mei"
                ></OfferBanner>
                <LoadingOverlay
                active={isLoading}
                spinner
                text='Asteptati un moment...'>
                <div className={classes.reviewPageContent}>
                    {reviews?.recenzii?.map((review, index) => (
                        <div key={index} className={classes.longReviewCard}>
                            <h3>
                                {review.autor}
                            </h3>
                            <div dangerouslySetInnerHTML={{ __html: review.descriere.html }} />
                        </div>
                    ))}
                </div>
                </LoadingOverlay>
            </section>
        </>
    )
}

export default LongTestimonials;
