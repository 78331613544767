import React, {useState} from 'react';

const SidebarContext = React.createContext({
    isSidebarOpen: false,
    isSidebarVisible: false,
    open: () => {},
    inView: () => {},
    close: () => {}
})

export const SidebarContextProvider = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const openSidebar = () => {
        setIsOpen(true);
    }

    const viewSidebar = () => {
        setIsVisible(true)
    };

    const closeSidebar = () => {
        setIsVisible(false);
        setTimeout(() => {
            setIsOpen(false);
        },200)
    }

    return (<SidebarContext.Provider value={{
        isSidebarOpen: isOpen,
        isSidebarVisible: isVisible,
        open: openSidebar,
        inView: viewSidebar,
        close: closeSidebar
    }}>{props.children}</SidebarContext.Provider>)
}

export default SidebarContext;