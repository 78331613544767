import { useContext, useEffect, useState } from 'react';
import classes from './Sidebar.module.css';
import doodle from '../../../assets/earth.png'
import highlight from '../../../assets/highlight_line_white.png'
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import SidebarContext from '../../../utils/context/SidebarContext';
function Sidebar (props) {
    const { t } = useTranslation();
    const hygraph = t;

    const sidebarCtx = useContext(SidebarContext)

    useEffect(() => {
        sidebarCtx.inView();
    }, []);

    function closeAll() {
        sidebarCtx.close();
    }

    return (
        <>
            <div className={sidebarCtx.isSidebarVisible? classes.overlay : classes.overlayClosing} onClick={closeAll}></div>
            <div className={sidebarCtx.isSidebarVisible? classes.sidebar : classes.sidebarClosing}>
                <div className={classes.buttonsContainer}>
                    <HashLink smooth={true} to="/#about-us" className={classes.item} onClick={closeAll}>{hygraph('Config.AboutMeLabel')}</HashLink>
                    <img src={highlight} alt="underline highlight drawing" className={classes.highlight}/>
                    <HashLink smooth={true} to="/#demo" className={classes.item} onClick={closeAll}>Ce vei învăța</HashLink>
                    <img src={highlight} alt="underline highlight drawing" className={classes.highlight}/>
                    <HashLink smooth={true} to="/#offer" className={classes.item} onClick={closeAll}>{hygraph('Config.MyOfferLabel')}</HashLink>
                    <img src={highlight} alt="underline highlight drawing" className={classes.highlight}/>
                    <HashLink smooth={true} to="/reviews#reviews" className={classes.item} onClick={closeAll}>{hygraph('Config.ReviewsLabel')}</HashLink>
                    <img src={highlight} alt="underline highlight drawing" className={classes.highlight}/>
                    <HashLink smooth={true} to="/register#form" className={classes.sidebarButton} onClick={closeAll}>{hygraph('Config.RegisterLabel')}</HashLink>
                    <img src={doodle} alt="planet earth drawing" className={classes.doodle}/>
                </div>
            </div>
        </>
    )
}

export default Sidebar;