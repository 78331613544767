import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setupI18n } from './i18n';
import { hydrate, render } from "react-dom";
import {
  BrowserRouter,
} from 'react-router-dom';

setupI18n(() => {
    const rootElement = document.getElementById("root");

    if (rootElement.hasChildNodes()) {
        hydrate(<BrowserRouter><App/></BrowserRouter>, rootElement);
    } else {
        render(<BrowserRouter><App/></BrowserRouter>, rootElement);
    }
    
    reportWebVitals();
});
