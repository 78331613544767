import classes from './CarouselCard.module.css'
import starsDoodle from '../../assets/stars (2).png'
import lineDoodle from '../../assets/line.png'

function CarouselCard(props) {
    return (
        <div className={classes.cardWrapper}>
            <div className={classes.text}>{props.text}</div>
            <div className={classes.user}>
                <img src={lineDoodle} alt="line doodle" className={classes.lineDoodle}/>
                <h6>{props.user}</h6>
                <img src={lineDoodle} alt="line doodle" className={classes.lineDoodle}/>
                </div>
            <img src={starsDoodle} alt="stars doodle" className={classes.starsDoodle}/>
        </div>
    )
}

export default CarouselCard;