import React from 'react';
import classes from '../GenericCourses.module.css';

import OfferBanner from '../../../components/OfferBanner';

import studentsBannerImage from "../../../assets/student-banner.jpg";
import studentPhoto1 from "../../../assets/student1.jpg";
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive'

const StudentCourses = () => {
    const { t } = useTranslation('translation');
    const hygraph = t;

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1199px)' });

    return (
        <section id="description">
        <div>
            <OfferBanner bannerImage={studentsBannerImage}
                bannerAlt="imagine de prezentare a serviciilor pentru studenti"
                bannerTitle={hygraph('Courses.Students.Title')}
            ></OfferBanner>

            <div className={classes.masonryWithColumns}>
                <div dangerouslySetInnerHTML={{ __html: hygraph('Courses.Students.Section1')} } className={!isTabletOrMobile ? classes.offerSection : classes.offerSectionMobile} />
                <div className={classes.offerSection}>
                    <div className={classes.imageFrame}>
                        <img src={studentPhoto1} alt='student'>
                        </img>
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: hygraph('Courses.Students.Section2')} } className={classes.offerSection} />
            </div>

            <div className={classes.registryButton}>
                <HashLink smooth={true} to='/register#form'>
                    <button className='primary-button big-button'>
                        {hygraph('Courses.Students.ButtonLabel')}
                    </button>
                </HashLink>
            </div>
        </div>
        </section>
    )
}

export default StudentCourses;
