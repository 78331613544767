import CarouselCard from "./CarouselCard";
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import classes from './CarouselComponent.module.css'
import { useState } from "react";
import arrow from '../../assets/arrow_carousel.png'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from "react-i18next";

const chunkArray = (items, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < items.length; i += chunkSize) {
      chunks.push(items.slice(i, i + chunkSize));
    }
    return chunks;
};

function CarouselComponent() {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1220px)' })

    const [currentSlide, setCurrentSlide] = useState(0);

    const groupSize = 3; // slide indexing begins at 0

    function totalNumberOfSlides() {
        return isTabletOrMobile ? allReviews.length : reviewsGroupedBy3.length;
    }

    function nextSlide() {
        currentSlide < totalNumberOfSlides() - 1 ? setCurrentSlide(currentSlide + 1) : setCurrentSlide(0);
    }

    function prevSlide() {
        currentSlide > 0? setCurrentSlide(currentSlide - 1) : setCurrentSlide(totalNumberOfSlides() - 1);
    }

    function updateCurrentSlide(index) {
        if(currentSlide !== index) {
            setCurrentSlide(index)
        }
    }

    const { t } = useTranslation('translation');
    const hygraph = t;
    const allReviews = hygraph('Reviews.ReviewList', {"returnObjects": true});
    const reviewsGroupedBy3 = chunkArray(allReviews, groupSize);

    return (
        <>
            {! isTabletOrMobile && <>
                <Carousel 
                showThumbs={false}
                showIndicators={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={7000}
                stopOnHover={false}
                transitionTime={1000}
                showArrows={false}
                // centerMode={true} this looks nice, more study needed
                showStatus={false}
                onChange={updateCurrentSlide}
                selectedItem={currentSlide}
                >
                    {reviewsGroupedBy3.map((group, index) => (
                        <div key={index} className={classes.carouselContentContainer}>
                        {group.map((review, idx) => (
                            <CarouselCard key={idx} text={review.Description} user={review.Author} />
                        ))}
                        </div>
                    ))}

                </Carousel>
                <div className={classes.carouselArrowsContainer}>
                    <img src={arrow} alt="carousel arrow" onClick={prevSlide} className={classes.arrowLeft}/>
                    <img src={arrow} alt="carousel arrow" onClick={nextSlide} className={classes.arrowRight}/>     
                </div>
            </>}
            {isTabletOrMobile && <><Carousel 
                showThumbs={false}
                showIndicators={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={7000}
                stopOnHover={false}
                transitionTime={800}
                showArrows={false}
                showStatus={false}
                onChange={updateCurrentSlide}
                selectedItem={currentSlide}
                >
                    {allReviews.map((review, index) => (
                        <div key={index} className={classes.carouselContentContainer}>
                            <CarouselCard text={review.Description} user={review.Author} />
                        </div>
                    ))}
                </Carousel>
                <div className={classes.carouselArrowsContainerMobile}>
                    <img src={arrow} alt="carousel arrow" onClick={prevSlide} className={classes.arrowLeft}/>
                    <img src={arrow} alt="carousel arrow" onClick={nextSlide} className={classes.arrowRight}/>     
                </div>
            </>}
        </>
    )
}

export default CarouselComponent;