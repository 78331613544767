import { MessagingUtil } from '../../services/util/MessagingUtil';
import classes from './DirectContactButtons.module.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import { CookieConsentService } from '../../services/CookieConsentService'; 
import React, { useState, useEffect } from 'react';

function DirectContactButtons() {
    const [showCookieConsent, setShowCookieConsent] = useState(false);

    useEffect(() => {
        const consent = CookieConsentService.getCookieConsent();
        setShowCookieConsent(consent === null);
    }, []);

    const onAcceptCookies = () => {
        CookieConsentService.setCookieConsent(true);
        setShowCookieConsent(false);
    };

    const onRefuseCookies = () => {
        CookieConsentService.setCookieConsent(false);
        setShowCookieConsent(false);
    };

    return (
        <div className={classes.stickyOverlayContainer}>
            <div className={classes.directContactContainer}>
                <button className={classes.whatsappButton} onClick={() => MessagingUtil.openWhatsapp()}>
                    <WhatsAppIcon fontSize='large'/>
                </button>
                <button className={classes.facebookButton} onClick={() => MessagingUtil.openFacebook()}>
                    <FacebookIcon fontSize='large'/>
                </button>
                <button className={classes.messengerButton} onClick={() => MessagingUtil.openMessenger()}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800"><radialGradient id="a" cx="101.9" cy="809" r="1.1" gradientTransform="matrix(800 0 0 -800 -81386 648000)" gradientUnits="userSpaceOnUse"><stop offset="0" style={{"stopColor":"#09f"}}/><stop offset=".6" style={{"stopColor":"#a033ff"}}/><stop offset=".9" style={{"stopColor":"#ff5280"}}/><stop offset="1" style={{"stopColor":"#ff7061"}}/></radialGradient><path fill="url(#a)" d="M400 0C174.7 0 0 165.1 0 388c0 116.6 47.8 217.4 125.6 287 6.5 5.8 10.5 14 10.7 22.8l2.2 71.2a32 32 0 0 0 44.9 28.3l79.4-35c6.7-3 14.3-3.5 21.4-1.6 36.5 10 75.3 15.4 115.8 15.4 225.3 0 400-165.1 400-388S625.3 0 400 0z"/><path fill="#FFF" d="m159.8 501.5 117.5-186.4a60 60 0 0 1 86.8-16l93.5 70.1a24 24 0 0 0 28.9-.1l126.2-95.8c16.8-12.8 38.8 7.4 27.6 25.3L522.7 484.9a60 60 0 0 1-86.8 16l-93.5-70.1a24 24 0 0 0-28.9.1l-126.2 95.8c-16.8 12.8-38.8-7.3-27.5-25.2z"/>
                    </svg>
                </button>
            </div>
            {showCookieConsent && (
                <div className={classes.cookieConsentBanner}>
                    <div className={classes.cookieConsentContent}>
                        <div>
                            Site-ul nostru folosește atât cookie-uri strict necesare, cât și cookie-uri opționale 
                            care ne ajută să îmbunătățim site-ul și să înțelegem mai bine nevoile vizitatorilor.
                        </div>
                        <div>
                            <button className='primary-button small-button' 
                             onClick={onAcceptCookies}>
                                Acceptă cookie-uri opționale
                            </button>
                            <button className='secondary-button small-button' 
                             onClick={onRefuseCookies}>
                                Refuză cookie-uri opționale
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DirectContactButtons;