import './App.css';
import HomePage from './pages/home/HomePage.tsx';
import AboutPage from './pages/AboutPage.tsx';
import ContactPage from './pages/ContactPage.tsx';
import LongTestimonials from './pages/testimonials/LongTestimonials.jsx';

import CompanyCourses from './pages/courses/companies/CompanyCourses.jsx';
import MedicalCourses from './pages/courses/medical/MedicalCourses.jsx';
import StudentCourses from './pages/courses/students/StudentCourses.jsx';
import ServicesOffer from './pages/courses/services/ServicesOffer.jsx';

import { useLocation } from 'react-router-dom';

import {
  Routes,
  Route
} from 'react-router-dom';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import DirectContactButtons from './components/layout/DirectContactButtons';
import RegistrationForm from './pages/registration/RegistrationForm';
import { SidebarContextProvider } from './utils/context/SidebarContext.jsx';
import { useEffect } from 'react';

function App() {
  document.title = "Școală de germană";

  const location = useLocation();
  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_path: location.pathname
    });
  }, [location]);

  // comments are to remind us to test prerender with & without <nav> component for SEO
  return (
    <SidebarContextProvider>
      <div className="App">
        {/* <header className="App-header">
          This is the header.
        </header> */}
                                                                            
        {/* <nav>
            <ul>
              <li>
                <HashLink smooth={true} to="/">Home</HashLink>
              </li>
              <li>
                <HashLink smooth={true} to="/about">About</HashLink>
              </li>
              <li>
                <HashLink smooth={true} to="/about/child">About details</HashLink>
              </li>
              <li>
                <HashLink smooth={true} to="/contact">Contact</HashLink>
              </li>
            </ul>
          </nav> */}
        <Header/>
        <div className='main-container'>
          <DirectContactButtons/>
          <Routes>
            <Route path="/" element={ <HomePage/> }></Route>
            <Route path="about" element={ <AboutPage/> }></Route>
            <Route path="/about/child" element={ <AboutPage/> }></Route>
            <Route path="contact" element={ <ContactPage/> }></Route>
            <Route path="/courses/companies" element={ <CompanyCourses/> }></Route>
            <Route path="/courses/medical" element={ <MedicalCourses/> }></Route>
            <Route path="/courses/students" element={ <StudentCourses/> }></Route>
            <Route path="/services" element={ <ServicesOffer/> }></Route>
            <Route path="/register" element={ <RegistrationForm/> }></Route>
            <Route path="/reviews" element={ <LongTestimonials/> }></Route>
          </Routes>
          <Footer/>
        </div>
      </div>
    </SidebarContextProvider>
  );
}

export default App;
