import React, { useState } from 'react';
import classes from "./RegistrationForm.module.css";
import { useForm } from 'react-hook-form';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';

const RegistrationForm = () => {
    const { t } = useTranslation('translation');
    const hygraph = t;

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [ successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [ successErrorOpen, setSuccessErrorOpen] = useState(false);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessSnackbarOpen(false);
        setSuccessErrorOpen(false);
    };

    // TODO create mailing service
    const onSubmit = async (data) => {

        window.gtag('event', 'form_complete_fail_or_success', { });
        console.log(data);

        // TODO move to env and check for success/fail
        emailjs.send('scoala_de_germana', 'template_83rs2jq', data, 'td13GVUttnZqtFMTU')
            .then((result) => {
                setSuccessSnackbarOpen(true);
                console.log(result.text);
            }, (error) => {
                window.gtag('event', 'form_complete_fail', { });
                setSuccessErrorOpen(true);
                console.log(error.text);
        });

        reset();
    };

    return (
        // TODO make this code generic for other forms
        <section id="form">
        <div className={classes.registrationPage}>
            <Snackbar
                open={successSnackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Vă mulțumim! Cererea dvs. de înscriere a fost trimisă.
                </Alert>
            </Snackbar>
            <Snackbar
                open={successErrorOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    A intervenit o problema. Vă rugăm să încercați și mai târziu.
                </Alert>
            </Snackbar>

            <form onSubmit={handleSubmit(onSubmit)} className={classes.registerForm}>
                <h1>{hygraph("ContactForm.Title")}</h1>
                <p>
                    {hygraph("ContactForm.Description")}
                </p>

                <div className={`${classes.inputField} ${classes.inputFieldSmall}`}>
                    <input required
                        {...register("name", {
                            required: "Numele și prenumele sunt obligatorii.",
                        })}
                    />
                    <label htmlFor="name">Nume și prenume</label>
                    {errors.name && <p className={classes.errorMessage}>{errors.name.message}</p>}
                </div>

                <div className={`${classes.inputField} ${classes.inputFieldSmall}`}>
                    <input required
                        {...register("city", {
                            required: "Câmpul pentru oraș este obligatoriu.",
                        })}
                    />
                    <label htmlFor="city">Oraș</label>
                    {errors.city && <p className={classes.errorMessage}>{errors.city.message}</p>}
                </div>

                <div className={`${classes.inputField} ${classes.inputFieldSmall}`}>
                    <input required
                        {...register("email", {
                            required: "Câmpul pentru email este obligatoriu.",
                            pattern: {
                                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                                message: "Adresa de email are un format invalid, verificați dacă adresa este corectă."
                            }
                        })}
                    />
                    <label htmlFor="email">Email</label>
                    {errors.email && <p className={classes.errorMessage}>{errors.email.message}</p>}
                </div>

                <div className={`${classes.inputField} ${classes.inputFieldSmall}`}>
                    <input required
                        {...register("phone", {
                            required: "Câmpul pentru numărul de telefon este obligatoriu.",
                            pattern: {
                                value: /^\d{10}$/,
                                message: "Numărul de telefon este invalid, folosiți 10 cifre."
                            }
                        })}
                    />
                    <label htmlFor="phone">Număr de telefon</label>
                    {errors.phone && <p className={classes.errorMessage}>{errors.phone.message}</p>}
                </div>

                <div className={classes.inputField}>
                    <textarea required rows="8"
                        {...register("details", {
                            required: "Câmpul pentru detalii este obligatoriu.",
                        })}
                        placeholder={hygraph("ContactForm.FormPlaceholder")}
                    />
                    {errors.details && <p className={classes.errorMessage}>{errors.details.message}</p>}
                </div>

                <div>
                        <input required
                            type="checkbox" 
                            {...register("consent", {
                                required: "Acordul dumneavoastră este necesar.", 
                            })}
                        />
                        <label htmlFor="consent">
                        Sunt de acord cu prelucrarea datelor cu caracter personal
                    </label>
                    {errors.consent && <p className={classes.errorMessage}>{errors.consent.message}</p>}
                </div>

                <div className={`${classes.submitButton} big-button`}>
                    <button className='secondary-button'>
                    {hygraph("ContactForm.ButtonLabel")}
                    </button>
                </div>

                <p>{hygraph("ContactForm.AlternativeContactText")}</p>
            </form>
        </div>
        </section>
    )
}

export default RegistrationForm;
