import classes from "./OfferBanner.module.css"

import textUnderline from "../assets/highlight_line.png";

function OfferBanner(props) {
    const { bannerImage, bannerTitle, bannerAlt } = props; 

    return (
        <>
            <div className={classes.offerBanner}>
                <img src={bannerImage} alt={bannerAlt}></img>
                <div id={classes.offerBannerFilter}> </div>
                <div className={classes.underlinedTitle}>
                    <h2>
                        {bannerTitle}
                    </h2>
                    <img src={textUnderline}></img>
                </div>
            </div>
        </>
    )
}

export default OfferBanner;