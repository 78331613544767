import { useState } from 'react';
import './Flipcard.css';
import { useMediaQuery } from 'react-responsive';

function Flipcard(props) {
    let [checkbox,setCheckbox] = useState(false);
    
    const smallScreen = useMediaQuery({ query: '(max-width: 500px)' })
    const cardHeight = (checkbox && smallScreen) ? {height: "440px"} : {}

    function handleToggle() {
        setCheckbox(!checkbox);
    }

    return (
        <>
            {smallScreen &&
            <div className="small-card" style={cardHeight}>
                <input type="checkbox" id={props.id} className="more" aria-hidden="true" onChange={handleToggle}/>
                <div className="content">
                    <div className="front">
                        <div className="inner">
                            <h2>{props.title}</h2>
                            <label htmlFor={props.id} className="button" aria-hidden="true">
                                Citește mai mult
                            </label>
                        </div>
                    </div>
                    <div className="back">
                        <div className="inner">
                            <div className="description">
                                <p>{props.description}</p>
                            </div>
                            <label htmlFor={props.id} className="button return" aria-hidden="true">
                                Înapoi
                            </label>
                        </div>
                    </div>
                </div>
            </div>}
            {!smallScreen &&
            <div className="big-card" style={cardHeight}>
                <input type="checkbox" id={props.id} className="more" aria-hidden="true" onChange={handleToggle}/>
                <div className="content">
                    <div className="front">
                        <div className="inner">
                            <h2>{props.title}</h2>
                            <label htmlFor={props.id} className="button" aria-hidden="true">
                                Citește mai mult
                            </label>
                        </div>
                    </div>
                    <div className="back">
                        <div className="inner">
                            <div className="description">
                                <p>{props.description}</p>
                            </div>
                            <label htmlFor={props.id} className="button return" aria-hidden="true">
                                Înapoi
                            </label>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default Flipcard;