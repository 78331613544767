import classes from "../HomePage.module.css"
import clasroomBg from '../../../assets/1.png'
import arrowDoodleWhite from '../../../assets/arrow_white.png'
import arrowDoodle from '../../../assets/arrow.png'
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive'
import { HashLink } from "react-router-hash-link";

function MainSection() {
    const { t } = useTranslation('translation');
    const hygraph = t;

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1220px)' });

    return (
        <>
            {!isTabletOrMobile && <>
                <div className={classes.mainSection}>
                    <div className={classes.mainHeader}>{hygraph('Home.MainSectionTitle')}</div>
                    <div className={classes.mainText}>{hygraph('Home.MainSectionDescription')}
                    </div>
                    <HashLink smooth={true} to="/#about-us">
                        <img src={arrowDoodleWhite} alt="arrow doodle" className={classes.arrowDoodle}/>
                        <div className={classes.bellowArrowDoodleText}><b>{hygraph('Config.SeeMoreLabel')}</b></div>
                    </HashLink>
                    <div className={classes.backgroundFilter}></div>
                    <img src={clasroomBg} alt="class room" className={classes.mainClassRoomBg}/>
                </div>
            </>}
            {isTabletOrMobile && <>
                <div className={classes.mainSection}>
                    <div className={classes.mainHeaderMobile}>{hygraph('Home.MainSectionTitle')}</div>
                    <div className={classes.mainTextMobile}>{hygraph('Home.MainSectionDescription')}
                    </div>
                    <HashLink smooth={true} to="/#about-us">
                        <img src={arrowDoodle} alt="arrow doodle" className={classes.arrowDoodle}/>
                        <div className={classes.bellowArrowDoodleTextMobile}><b>{hygraph('Config.SeeMoreLabel')}</b></div>
                    </HashLink>
                </div>
            </>}
        </>
    )
}

export default MainSection;