import classes from "../HomePage.module.css"
import linesDoodle from "../../../assets/doodle_lines.png"
import exclamationDoodle from "../../../assets/exclamation_sign.png"
import earthDoodle from "../../../assets/earth.png"
import smileyDoodle from "../../../assets/smiley_face.png"
import starsDoodle from "../../../assets/stars.png"
import textUnderline from "../../../assets/highlight_line_white.png";
import { HashLink } from 'react-router-hash-link';
import { useMediaQuery } from 'react-responsive';
import CelebrationIcon from '@mui/icons-material/Celebration';

import React from "react"
import { useTranslation } from 'react-i18next';

function OfferSection() {
    const { t } = useTranslation('translation');
    const hygraph = t;

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1220px)' })

    return (
        <>
            <div>
                <div className={classes.offerSection}>
                    <div className={classes.offerDoodles}>
                        <img src={earthDoodle} alt="bubbles doodle" className={classes.globeDoodle}/>
                        <img src={exclamationDoodle} alt="bubbles doodle" className={classes.exclamationDoodle}/>
                        <img src={linesDoodle} alt="bubbles doodle" className={classes.linesDoodle}/>
                        <img src={smileyDoodle} alt="bubbles doodle" className={classes.smileyDoodle}/>
                        <img src={starsDoodle} alt="bubbles doodle" className={classes.starsDoodle}/>
                    </div>
                    <div className={classes.offerContent}>
                        <h2 className={!isTabletOrMobile? classes.offerHeader : classes.offerHeaderMobile}>{hygraph('Home.OfferTitle')}</h2>
                        <img src={textUnderline} className={!isTabletOrMobile? classes.textUnderline : classes.textUnderlineMobile} alt="underline highlight doodle"></img>
                        <div className={classes.offerCardsContainer}>
                            <div className={classes.offerCard}>
                                <p className={!isTabletOrMobile? classes.offerText : classes.offerTextMobile}>
                                Cursuri personalizate de limba germană <b>până la nivel C1</b>, special concepute pentru promovarea examenelor lingvistice precum Goethe, Telc, DaF, etc.
                                </p>

                                <HashLink smooth={true} to='/courses/students#description' className={`secondary-button ${classes.offerButton}`}>
                                    <button> {hygraph('Home.OfferButtonLabel')} </button>
                                </HashLink>
                            </div>
                            <div className={classes.offerCard}>
                                <p className={!isTabletOrMobile? classes.offerText : classes.offerTextMobile}>
                                Cursuri de limba germană <b>pentru medici</b>, pentru promovarea cu succes a examenului lingvistic FSP în Germania.
                                </p>

                                <HashLink smooth={true} to="/courses/medical#description" className={`secondary-button ${classes.offerButton}`}>
                                    <button> {hygraph('Home.OfferButtonLabel')} </button>
                                </HashLink>
                            </div>
                            <div className={classes.offerCard}>
                                <p className={!isTabletOrMobile? classes.offerText : classes.offerTextMobile}>
                                Cursuri de limba germană <b>pentru companii</b>
                                </p>

                                <HashLink smooth={true} to="/courses/companies#description" className={`secondary-button ${classes.offerButton}`}>
                                    <button> {hygraph('Home.OfferButtonLabel')} </button>
                                </HashLink>
                            </div>
                            <div className={classes.offerCard}>
                                <p className={!isTabletOrMobile? classes.offerText : classes.offerTextMobile}>
                                Servicii de <b>traducere si interpretariat</b> română-germană-engleză
                                </p>

                                <HashLink smooth={true} to="/services#description" className={`secondary-button ${classes.offerButton}`}>
                                <button>
                                    {hygraph('Home.OfferButtonLabel')}
                                </button>
                                </HashLink>
                            </div>
                        </div>
                        <div className={classes.freeTrialBanner}>
                            <CelebrationIcon fontSize='large'/>
                            <p>{hygraph('Home.DemoLesson')}</p>
                        </div>
                        
                        <HashLink smooth={true} to='/register#form' className={`${classes.offerRegisterButton} big-button`}>
                            <button className="secondary-button">
                                Înscrie-te
                            </button>
                        </HashLink>
                    </div>
                </div>
            </div>
        </>
        
    )
}

export default OfferSection;