import classes from './Footer.module.css';
import logo from '../../assets/footer_logo.png';

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import { HashLink } from 'react-router-hash-link';

import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation('translation');
    const hygraph = t;

    let currentYear = new Date().getFullYear();
    console.log(currentYear);

    return (
        <footer>
            <div className={classes.footerMainContainer}>
                <div className={classes.footerMainContent}>
                    <div className={`${classes.footerColumn}`}>
                        <HashLink smooth={true} to='/#home'> <img src={logo}></img> </HashLink>
                    </div>
                    <div className={`${classes.footerColumn}`}>
                        <HashLink smooth={true} to='/#about-us'> <p> {hygraph('Config.AboutMeLabel')} </p> </HashLink>
                        <HashLink smooth={true} to='/#offer'> <p>{hygraph('Config.MyOfferLabel')}</p> </HashLink>
                        <HashLink smooth={true} to='/reviews#reviews'> <p>{hygraph('Config.ReviewsLabel')}</p> </HashLink>
                        <HashLink smooth={true} to='/register#form'> <p>{hygraph('Config.RegisterLabel')}</p> </HashLink>
                    </div>

                    <div className={`${classes.footerColumn}`}>
                        <h2>Contact</h2>
                        {/* <p>
                            <LocationOnIcon fontSize='small' 
                                className={classes.contactIcon}/>
                            {hygraph('Config.Address')} 
                        </p> */}

                        <p>
                            <EmailIcon fontSize='small' 
                                className={classes.contactIcon}/>
                            {hygraph('Config.Email')} 
                        </p>

                        <p>
                            <LocalPhoneIcon fontSize='small' 
                                className={classes.contactIcon}/>
                            {hygraph('Config.PhoneNumber')} 
                        </p>
                    </div>
                </div>
                <div className={classes.footerSignature}>
                    <p>Site realizat de: 
                    <a className={classes.emojiReference} 
                        href="https://www.linkedin.com/in/daniel-t-6661621ba/" target="_blank">🦆</a>
                    <a className={classes.emojiReference} 
                        href="https://www.linkedin.com/in/filip-tasadan/" target="_blank">🤠</a>
                    <a className={classes.emojiReference} 
                        href="https://www.linkedin.com/in/ianis-teja-b25b1719a/" target="_blank">🏂</a>
                    <a className={classes.emojiReference} 
                        href="https://www.linkedin.com/in/romulus-andrei-bacila-662b1b196/" target="_blank">🦖</a>
                    </p>

                    <p>{currentYear} © ScoalaMeaDeGermana, Toate drepturile rezervate</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;