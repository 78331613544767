import classes from "../HomePage.module.css"
import hatDoodle from "../../../assets/hat.png"
import booksDoodle from "../../../assets/bookstack.png"
import arrowDoodle from "../../../assets/arrow.png"
import townDoodle from "../../../assets/city.png"
import schoolBg from "../../../assets/teaching.png"
import planeDoodle from "../../../assets/plane.png"
import highlightDoodle from "../../../assets/highlight_line.png"
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from "react-i18next"
import { HashLink } from "react-router-hash-link"

function AboutUsSection() {
    const { t } = useTranslation('translation');
    const hygraph = t;

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1220px)' })

    return (
        <>
            {!isTabletOrMobile && <>
                <div className={classes.container}>
                    <div className={classes.part}>
                        {/* left side of the section */}
                        <div className={classes.sectionContainer}>
                            <div dangerouslySetInnerHTML={{ __html: hygraph('HTML.AboutUsBulletPoints')} } className={classes.aboutUsBulletPointList} />
                            <img src={hatDoodle} alt="hat doodle" className={classes.hatDoodle}/>
                            <img src={schoolBg} alt="class room" className={classes.classRoomBg}/>
                        </div>
                    </div>
                    <div className={classes.part}>
                        {/* right side of the section */}
                        <div className={classes.sectionContainer}>
                            <img src={planeDoodle} alt="plane doodle" className={classes.mainDoodle}/>
                            <h1 className={classes.aboutUsHeader}>{hygraph('Home.AboutMeTitle')}</h1>
                            <div className={classes.paragraphGroup}>
                                <p className={classes.aboutUsParaghraph}> {hygraph('Home.AboutMeParagraph1')} </p>
                                <p className={classes.aboutUsParaghraph}> {hygraph('Home.AboutMeParagraph2')} </p>
                            </div>
                            <div className={classes.doodlesGroup}>
                                <HashLink smooth={true} to="/#demo">
                                    <img src={arrowDoodle} alt="arrow doodle" className={classes.rightSideArrowDoodle}/>
                                </HashLink>
                            </div>
                            <img src={townDoodle} alt="town doodle" className={classes.townDoodle}/>
                        </div>
                    </div>
                </div>
            </>}
            {isTabletOrMobile && <>
                <div className={classes.mainSection} style={{minHeight: "750px"}}>
                    <h1 className={classes.aboutUsHeaderMobile}>{hygraph('Home.AboutMeTitle')}</h1>
                    <div className={classes.paragraphGroup}>
                        <p className={classes.aboutUsParaghraphMobile}> {hygraph('Home.AboutMeParagraph1')} </p>
                        <p className={classes.aboutUsParaghraphMobile}> {hygraph('Home.AboutMeParagraph2')} </p>
                    </div>
                    <img src={booksDoodle} alt="book stack doodle" className={classes.aboutUsBookDoodle}/>
                    <img src={highlightDoodle} alt="underline highlight line" className={classes.aboutUsHighlightDoodleMobile}/>
                </div>
                <div className={classes.mainSection}>
                    <div dangerouslySetInnerHTML={{ __html: hygraph('HTML.AboutUsBulletPoints')} } className={classes.aboutUsBulletPointMobileList} />
                    <img src={schoolBg} alt="class room" className={classes.classRoomBgMobile}/>
                </div>
            </>}
        </>
        
    )
}

export default AboutUsSection;