import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { AppResourcesFetch } from './services/AppResourcesFetch';

export const setupI18n = (onCompleted) => {
    i18n.use(initReactI18next) 
        .init({
            resources: {
                ro: {
                    translation: {
                        Courses: {
                            Students: {
                                Title: "",
                                Section1: "",
                                Section2: "",
                                ButtonLabel: "",
                            },
                            Medical: {
                                Title: "",
                                Section1: "",
                                Section2: "",
                                ButtonLabel: "",
                            },
                            Company: {
                                Title: "",
                                Section1: "",
                                Section2: "",
                                ButtonLabel: "",
                            },
                        },
                        HTML: {
                            AboutUsBulletPoints: "<ul><li><b>NULL</b></li></ul>",
                        },
                        Home: {
                            MainSectionTitle: '_NULL_MainSectionTitle',
                            MainSectionDescription: '_NULL_MainSectionDescripion',
                            AboutMeTitle: '_NULL_AboutMeSectionTitle',
                            AboutMeParagraph1: '_NULL_AboutMeSectionParagraph1',
                            AboutMeParagraph2: '_NULL_AboutMeSectonParagraph2',
                            OfferTitle: '_NULL_OfferTitle',
                            OfferCard1: '_NULL_OfferCard1',
                            OfferCard2: '_NULL_OfferCard2',
                            OfferCard3: '_NULL_OfferCard3',
                            OfferCard4: '_NULL_OfferCard4',
                            OfferButtonLabel: '_NULL_Label',
                        },
                        DemoSection: {
                            SectionTitle: '_NULL_SectionTitle',
                            SectionSubtitle: '_NULL_SectionSubtitle',
                            SectionDescription: '_NULL_SectionDescription',
                            GramaticsTitle: '_NULL_GramaticsTitle',
                            GramaticsDescription: '_NULL_GramaticsDescription',
                            SyntaxTitle: '_NULL_SyntaxTitle',
                            SyntaxDescription: '_NULL_SyntaxDescription',
                            VocabularyTitle: '_NULL_VocabularyTitle',
                            VocabularyDescription: '_NULL_VocabularyDescription',
                            OralTitle: '_NULL_OralTitle',
                            OralDescription: '_NULL_OralDescription',
                            FinalTitle: '_NULL_FinalTitle',
                            FinalDescription: '_NULL_FinalDescription',
                        },
                        Config: {
                            PhoneNumber: "_NULL_Phone",
                            AboutMeLabel: '_NULL_AboutMe',
                            MyOfferLabel: '_NULL_MyOffer',
                            RegisterLabel: '_NULL_Register',
                            Email: '_NULL_Email',
                            FacebookPage: '_NULL_Facebook',
                            SeeMoreLabel: '_NULL_VeziMaiMult'
                        },
                        Reviews: {
                            ReviewSectionTitle: '_NULL_ReviewSectionTitle',
                            ReviewList: []
                        },
                        LongReviews: {
                            PageTitle: '_NULL_PageTitle',
                            ReviewList: []
                        }
                    },
                },
            },
            lng: 'ro',
            fallbackLng: 'ro',
            interpolation: {
                escapeValue: false,
            },
            useSuspense: false
        });

    AppResourcesFetch.getHygraphData().then(data => {
        const paginaPrincipala = data.paginaPrincipalas[0];
        const navigare = data.navigares[0];
        const recenzii = data.sectiuneRecenziis[0];
        const students = data.cursStudentis[0];
        const doctors = data.cursDoctoris[0];
        const company = data.cursCompaniis[0];
        const detaliiTraduceri = data.detaliiTraduceris[0];
        const formular = data.paginaFormulars[0];

        i18n.addResourceBundle('ro', 'translation', {
            Courses: {
                Students: {
                    Title: students.titlu,
                    Section1: students.sectiune1.html,
                    Section2: students.sectiune2.html,
                    ButtonLabel: students.etichetaButon,
                },
                Medical: {
                    Title: doctors.titlu,
                    Section1: doctors.sectiune1.html,
                    Section2: doctors.sectiune2.html,
                    ButtonLabel: doctors.etichetaButon,
                },
                Company: {
                    Title: company.titlu,
                    Section1: company.sectiune1.html,
                    Section2: company.sectiune2.html,
                    ButtonLabel: company.etichetaButon,
                },
                Services: {
                    Title: detaliiTraduceri.titluPagina,
                    HTML: detaliiTraduceri.textDetalii.html,
                    ButtonLabel: detaliiTraduceri.etichetaButon,
                },
            },
            HTML: {
                AboutUsBulletPoints: paginaPrincipala.sintezaDespreMine.html,
            },
            Home: {
                MainSectionTitle: paginaPrincipala.titluPrincipal,
                MainSectionDescription: paginaPrincipala.descrierePrincipala,
                AboutMeTitle: paginaPrincipala.titluDescriere,
                AboutMeParagraph1: paginaPrincipala.descriereCineSuntem,
                AboutMeParagraph2: paginaPrincipala.despreMineParagraf2,
                OfferTitle: paginaPrincipala.ofertaMeaTitlu,
                OfferCard1: paginaPrincipala.carticicaCursuriStudent,
                OfferCard2: paginaPrincipala.carticicaCursuriCompanii,
                OfferCard3: paginaPrincipala.carticicaCursuriMedici,
                OfferCard4: paginaPrincipala.carticicaServiciiTraduceri,
                OfferButtonLabel: paginaPrincipala.butonCarticica,
                DemoLesson: paginaPrincipala.ofertaCursGratis,
            },
            DemoSection: {
                SectionTitle: paginaPrincipala.ceVeiInvataTitlu,
                SectionSubtitle: paginaPrincipala.patruComponenteStructurateTitlu,
                SectionDescription: paginaPrincipala.descriereComponenteStructurate,
                GramaticsTitle: paginaPrincipala.gramaticaTitlu,
                GramaticsDescription: paginaPrincipala.gramaticaDescriere,
                SyntaxTitle: paginaPrincipala.sintaxaTitlu,
                SyntaxDescription: paginaPrincipala.sintaxaDescriere,
                VocabularyTitle: paginaPrincipala.vocabularTitlu,
                VocabularyDescription: paginaPrincipala.vocabularDescriere,
                OralTitle: paginaPrincipala.vorbitTitlu,
                OralDescription: paginaPrincipala.vorbitDescriere,
                FinalTitle: paginaPrincipala.germanaCheieDeSuccesTitlu,
                FinalDescription: paginaPrincipala.germanaCheieDeSuccesDescriere,
            },
            Config: {
                PhoneNumber: navigare.telefon,
                AboutMeLabel: navigare.despreMine,
                MyOfferLabel: navigare.ofertaMea,
                RegisterLabel: navigare.inscrieri,
                Email: navigare.email,
                SeeMoreLabel: navigare.veziMaiMult,
                ReviewsLabel: navigare.recenzii,
                LearnLabel: navigare.ceVeiInvata
            },
            Reviews: {
                ReviewSectionTitle: recenzii.titluSectiune,
                ReviewList: recenzii.recenzii.map((rawRecenzie) => {
                    return {Author: rawRecenzie.autor, Description: rawRecenzie.recenzie}
                })
            },
            ContactForm: {
                Title: formular.titluPagina,
                Description: formular.descrierePagina,
                FormPlaceholder: formular.detaliiAditionaleFormular,
                AlternativeContactText: formular.explicatieAlternativa,
                ButtonLabel: formular.etichetaButon
            },
        }, true, true);

        onCompleted();
    })
};
