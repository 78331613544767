import React from 'react';
import classes from '../GenericCourses.module.css';

import OfferBanner from '../../../components/OfferBanner';

import servicesBannerImage from "../../../assets/services-banner.jpg";
import servicesPhoto from "../../../assets/service1.jpg";
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive'

const ServicesOffer = () => {
    const { t } = useTranslation('translation');
    const hygraph = t;

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1199px)' });

    return (
        <section id="description">
        <div>
            <OfferBanner bannerImage={servicesBannerImage}
                bannerAlt="imagine de prezentare a serviciilor de traducere si interpretare"
                bannerTitle={hygraph('Courses.Services.Title')}
            ></OfferBanner>

            <div className={classes.masonryWithColumns}>
                <div dangerouslySetInnerHTML={{ __html: hygraph('Courses.Services.HTML')} } className={!isTabletOrMobile ? classes.offerSection : classes.offerSectionMobile} />
                <div className={classes.offerSection}>
                    <div className={classes.imageFrame}>
                        <img src={servicesPhoto} alt='carte'>
                        </img>
                    </div>
                </div>
            </div>

            <div className={classes.registryButton}>
                <HashLink smooth={true} to='/register#form'>
                    <button className='primary-button big-button'>
                        {hygraph('Courses.Services.ButtonLabel')}
                    </button>
                </HashLink>
            </div>
        </div>
        </section>
    )
}

export default ServicesOffer;
