import classes from '../HomePage.module.css'
import highlight from '../../../assets/highlight_line_white.png'
import romb from '../../../assets/romb.png'
import bubbleDoodle from "../../../assets/bubbles.png"
import underlineDoodle from "../../../assets/highlight_line_white.png"
import CarouselComponent from "../../../components/carousel/CarouselComponent";
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from "react-i18next";
import Flipcard from '../../../components/flipcard/Flipcard'

function DemoSection() {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1220px)' })
    const wrapperMaxWidth = useMediaQuery({ query: '(max-width: 1810px)' })

    const { t } = useTranslation('translation');
    const hygraph = t;

    const flipcardsContainerStyles = {
        display: 'flex',
	    flexFlow: 'row wrap',
	    justifyContent: 'center',
        maxWidth: wrapperMaxWidth ? '1000px' : '100%'
    }

    return (
        <>
            <div className={classes.demoSection}>
                <h1 className={!isTabletOrMobile? classes.demoHeader : classes.demoHeaderMobile}>{hygraph('DemoSection.SectionTitle')}</h1>
                <img src={highlight} alt="underline highlight doodle" className={!isTabletOrMobile? classes.demoHighlight : classes.demoHighlightMobile}/>
                <div className={classes.demoMain} style={isTabletOrMobile? {height: '250px'} : {}}>
                    {!isTabletOrMobile && <img src={romb} alt="romb doodle" className={classes.demoRomb}/>}
                    <div className={!isTabletOrMobile? classes.demoCard : classes.demoCardMobile}>
                        <h3 className={classes.demoCardHeader}>{hygraph('DemoSection.SectionSubtitle')}</h3>
                        <p className={classes.demoCardText}>{hygraph('DemoSection.SectionDescription')}</p>
                    </div>
                    {!isTabletOrMobile && <img src={romb} alt="romb doodle" className={classes.demoRomb}/>}
                </div>
                <div className={!isTabletOrMobile? classes.demoMainContainer : classes.demoMainContainerMobile}>
                    <div style={flipcardsContainerStyles}>
                        <Flipcard title={hygraph('DemoSection.GramaticsTitle')} description={hygraph('DemoSection.GramaticsDescription')} id={"card1"}/>
                        <Flipcard title={hygraph('DemoSection.SyntaxTitle')} description={hygraph('DemoSection.SyntaxDescription')} id={"card2"}/>
                        <Flipcard title={hygraph('DemoSection.VocabularyTitle')} description={hygraph('DemoSection.VocabularyDescription')} id={"card3"}/>
                        <Flipcard title={hygraph('DemoSection.OralTitle')} description={hygraph('DemoSection.OralDescription')} id={"card4"}/>
                    </div>
                    <div className={classes.demoFooter}>
                        <h2 className={!isTabletOrMobile? classes.demoFooterHeader : classes.demoFooterHeaderMobile}>{hygraph('DemoSection.FinalTitle')}</h2>
                        <p className={!isTabletOrMobile? classes.demoFooterText : classes.demoFooterTextMobile}>{hygraph('DemoSection.FinalDescription')}</p>
                    </div>
                    <div className={!isTabletOrMobile? classes.testimonialsTitle : classes.testimonialsTitleMobile}>
                        <div className={!isTabletOrMobile? classes.titleHeader : classes.titleHeaderMobile}>{hygraph('Reviews.ReviewSectionTitle')}</div>
                        <img src={underlineDoodle} alt="bubbles doodle" className={!isTabletOrMobile? classes.highlight : classes.highlightMobile}/>
                    </div>
                </div>
                    <div className={classes.carouselContainer}>
                        <CarouselComponent/>
                        <div className={!isTabletOrMobile? classes.testimonialsDoodles : classes.testimonialsDoodlesMobile}>
                            <img src={bubbleDoodle} alt="bubbles doodle" className={classes.bubbleLeft}/>
                            <img src={bubbleDoodle} alt="bubbles doodle" className={classes.bubbleRight}/>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default DemoSection;
