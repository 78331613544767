export const MessagingUtil = {
    openWhatsapp: () => {
        let phoneNumber = "+40721865657"
        let message = "";

        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            window.open(`whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`);
        } else {
            window.open(`https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`);
        }
    },

    openMessenger: () => {
        let messengerUsername = "100003063130802";

        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            window.open(`fb-messenger://user/${messengerUsername}`);
        } else {
            window.open(`https://m.me/${messengerUsername}`);
        }
    },

    openInstagram: () => {
        let instagramUsername = "dani77q";

        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            window.open(`instagram://user?username=${instagramUsername}`);
        } else {
            window.open(`https://instagram.com/${instagramUsername}`);
        }
    },

    openFacebook: () => {
        let facebookPageId = "61556436629246";
    
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            window.open(`fb://profile/${facebookPageId}`);
        } else {
            window.open(`https://www.facebook.com/profile.php?id=${facebookPageId}`);
        }
    }    
}