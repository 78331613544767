import { createClient, gql, cacheExchange, fetchExchange } from '@urql/core';

const client = createClient({
  url: process.env.REACT_APP_CMS_HYGRAPH_URL,
  exchanges: [cacheExchange, fetchExchange],
});

export const AppResourcesFetch = {
  getLongReviews: async () => {
    const query = gql`
      query longReviews {
        recenziiLungis {
          titluPagina
          recenzii(first: 100) {
            autor
            descriere {
              html
            }
          }
        }
      }
    `;

    const { data, error } = await client.query(query).toPromise();
    if (error) {
      console.error('Error fetching long reviews:', error);
      return null;
    }
    return data;
  },

  getHygraphData: async () => {
    const query = gql`
      query hygraphData {
        detaliiTraduceris {
          titluPagina
          etichetaButon
          textDetalii {
            html
          }
        }
        cursDoctoris {
          titlu
          sectiune1 {
            html
          }
          sectiune2 {
            html
          }
          etichetaButon
        }
        cursStudentis {
          titlu  
          sectiune1 {
            html
          }
          sectiune2 {
            html
          }
          etichetaButon
        }
        cursCompaniis {
          titlu  
          sectiune1 {
            html
          }
          sectiune2 {
            html
          }
          etichetaButon
        }
        detaliiTraduceris {
          titluPagina
          textDetalii {
            html
          }
          etichetaButon
        }
        paginaPrincipalas {
          ofertaCursGratis
          descriereCineSuntem
          descrierePrincipala
          despreMineParagraf2
          ofertaMeaTitlu
          titluDescriere
          titluPrincipal
          carticicaCursuriMedici
          carticicaCursuriStudent
          carticicaCursuriCompanii
          carticicaServiciiTraduceri
          butonCarticica
          ceVeiInvataTitlu
          patruComponenteStructurateTitlu
          descriereComponenteStructurate
          gramaticaTitlu
          gramaticaDescriere
          sintaxaTitlu
          sintaxaDescriere
          vocabularTitlu
          vocabularDescriere
          vorbitTitlu
          vorbitDescriere
          germanaCheieDeSuccesTitlu
          germanaCheieDeSuccesDescriere
          sintezaDespreMine {
            html
          }
        }
        navigares {
          telefon
          despreMine
          ofertaMea
          ceVeiInvata
          recenzii
          inscrieri
          email
          veziMaiMult
        }
        sectiuneRecenziis {
          titluSectiune
          recenzii {
            autor
            recenzie
          }
        }
        paginaFormulars {
          titluPagina
          descrierePagina
          detaliiAditionaleFormular
          etichetaButon
          explicatieAlternativa
        }
      }
    `;

    const { data, error } = await client.query(query).toPromise();
    if (error) {
      console.error('Error fetching hygraph data:', error);
      return null;
    }
    return data;
  }
};
